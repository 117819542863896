import docsSidebar from "../../../data/sidebars/doc-links.yaml"
import contributingSidebar from "../../../data/sidebars/contributing-links.yaml"
import featuresSidebar from "../../../data/sidebars/features-links.yaml"
import tutorialSidebar from "../../../data/sidebars/tutorial-links.yaml"
import allDocs from "../../components/sidebar/all-docs-sidebar.yaml"

const createHash = link => {
  let index = -1
  if (link) index = link.indexOf(`#`)
  return index >= 0 ? link.substr(index + 1) : false
}

const extendItem = (items, parentTitle, level) => {
  items.forEach(item => {
    item.hash = createHash(item.link)
    item.parentTitle = parentTitle
    item.level = level || 1

    if (item.items) extendItem(item.items, item.title, item.level + 1)
  })
}

const extendItemList = itemList => {
  itemList.forEach(section => {
    section.level = 0
    if (section.items) extendItem(section.items, section.title)
  })
  return itemList
}

const extendSidebarData = item => {
  return {
    title: item[0].title,
    breadcrumbTitle: item[0].breadcrumbTitle,
    key: item[0].key,
    disableExpandAll: item[0].disableExpandAll,
    disableAccordions: item[0].disableAccordions,
    items: extendItemList(item[0].items),
  }
}

const itemListDocs = extendSidebarData(docsSidebar)
const itemListTutorial = extendSidebarData(tutorialSidebar)
const itemListContributing = extendSidebarData(contributingSidebar)
const itemListFeatures = extendSidebarData(featuresSidebar)

const itemListLookup = {
  docs: itemListDocs,
  contributing: itemListContributing,
  tutorial: itemListTutorial,
  features: itemListFeatures,
}

function getItemList(path) {
  const [urlSegment] = path.split(`/`).slice(1)
  return itemListLookup[urlSegment]
}

// this helper is necessary when URLs are being checked for equality
// eg ensuring /docs/tutorial matches /docs/tutorial
const addEndingSlash = path =>
  (!path || path.charAt(path.length - 1)) === "/" ? path : `${path}/`

// these helpers take a root section node,
// recursively find all children,
// and then check a URL to see if it is in the section
const flatten = arrays => [].concat(...arrays)

const getSectionLinks = (
  section,
  includeLandingPages = true,
  includeOverview = true
) => {
  const currentLink =
    section.to &&
    (includeLandingPages || !section.subItems) &&
    (includeOverview || !(section.itemType === "overview"))
      ? [addEndingSlash(section.to)]
      : []

  return [
    ...currentLink,
    ...(section.subItems
      ? flatten(
          section.subItems.map(item =>
            getSectionLinks(item, includeLandingPages, includeOverview)
          )
        )
      : []),
  ]
}

const isUrlInSection = (node, path) => {
  return new Set(getSectionLinks(node)).has(addEndingSlash(path))
}

const getCurrentDocsSection = pathname => {
  if (pathname.slice(0, 14) === `/docs/tutorial`) {
    return {
      sidebar: `Tutorial`,
      navBarIndex: 1,
    }
  } else if (isUrlInSection(allDocs[0].subItems[4], pathname)) {
    return {
      sidebar: `Reference`,
      navBarIndex: 3,
    }
  } else if (isUrlInSection(allDocs[0].subItems[5], pathname)) {
    return {
      sidebar: `Conceptual Guides`,
      navBarIndex: 4,
    }
  } else if (isUrlInSection(allDocs[0].subItems[3], pathname)) {
    return {
      sidebar: `How-to Guides`,
      navBarIndex: 2,
    }
  } else {
    return {
      sidebar: `Documentation`,
      navBarIndex: 0,
    }
  }
}

export {
  itemListDocs,
  itemListTutorial,
  itemListContributing,
  itemListFeatures,
  getItemList,
  getCurrentDocsSection,
  isUrlInSection,
  getSectionLinks,
}
